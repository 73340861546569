// Dutch
const lang = {
    contact: 'Kontakt',
    born: 'Geboren',
    bornIn: 'in',
    experience: 'Berufserfahrung',
    education: 'Schulbildung',
    skills: 'Qualifikationen',
    projects: 'Projekte',
    contributions: 'Mitarbeit',
    certificates: 'Zertifikate',
    see_certificate: 'Zertifikat ansehen',
    about: 'Über mich',
    spoken_lang: 'Sprachen'
};
export default lang;
