// Russian
const lang = {
    contact: 'Контакты',
    born: 'Родился',
    bornIn: 'в г.',
    experience: 'Опыт',
    education: 'Образование',
    skills: 'Навыки',
    projects: 'проектов',
    contributions: 'взносы',
    about: 'Обо мне'
};

export default lang;
