// English
const lang = {
    contact: 'Contact',
    born: 'Born',
    bornIn: 'in',
    experience: 'Experience',
    education: 'Education',
    skills: 'Skills',
    projects: 'Projects',
    contributions: 'Contributions',
    certificates: 'Certificates',
    see_certificate: 'see Certificate',
    about: 'About me',
    spoken_lang: 'Languages'
};
export default lang;
