// Nederlands
const lang = {
    contact: 'Contact',
    experience: 'Ervaringen',
    education: 'Opleidingen',
    skills: 'Skills',
    projects: 'Projecten',
    contributions: 'Bijdragen',
    about: 'Over mij'
};
export default lang;
