// Catalan
const lang = {
    contact: 'Contacte',
    experience: 'Experiència',
    education: 'Educació',
    skills: 'Habilitats',
    projects: 'Projectes',
    contributions: 'Contribucions',
    about: 'Sobre mi'
};
export default lang;
