import { render, staticRenderFns } from "./material-dark.vue?vue&type=template&id=162b13ba&scoped=true&"
import script from "./material-dark.vue?vue&type=script&lang=js&"
export * from "./material-dark.vue?vue&type=script&lang=js&"
import style0 from "./material-dark.vue?vue&type=style&index=0&id=162b13ba&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "162b13ba",
  null
  
)

component.options.__file = "material-dark.vue"
export default component.exports