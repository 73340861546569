const lang = {
    contact: '聯絡方式',
    experience: '經歷',
    education: '學歷',
    skills: '技能專長',
    projects: '專案',
    contributions: '貢獻',
    about: '自我介紹'
};
export default lang;
