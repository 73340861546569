// Korean
const lang = {
    contact: '연락처',
    experience: '경력',
    education: '학력',
    skills: '보유스킬',
    projects: '프로젝트',
    contributions: '기고',
    about: '자기소개'
};
export default lang;
