// Spanish
const lang = {
    contact: 'Contacto',
    experience: 'Experiencia',
    education: 'Educación',
    skills: 'Habilidades',
    projects: 'Proyectos',
    contributions: 'Contribuciones',
    about: 'Sobre mi'
};
export default lang;
