import { render, staticRenderFns } from "./left-right.vue?vue&type=template&id=1a15758d&scoped=true&"
import script from "./left-right.vue?vue&type=script&lang=js&"
export * from "./left-right.vue?vue&type=script&lang=js&"
import style0 from "./left-right.vue?vue&type=style&index=0&id=1a15758d&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a15758d",
  null
  
)

component.options.__file = "left-right.vue"
export default component.exports