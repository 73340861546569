/* #*/ export const PERSON = `
name:
  first: Engin
  middle: Guelhan
  last: Yilmaz
about:
position: Senior Drupal Developer

birth:
  year: 1991
  location: Berlin

experience:
- company: FFW Deutschland GmbH
  position: Senior Drupal Developer
  timeperiod: seit Juli 2015
  description:
    - "Entwicklung von Anwendungen, die auf das CMS Drupal basieren"
    - "Beratung von Kunden zu Drupal-Lösungen und Erweiterungen"
    - "technische Projektleitung und Lead für Kunden im deutschsprachigen Raum."
    - "Senior Drupal Developer seit 2017."
  website: https://ffwagency.com

- company: init AG für digitale Kommunikation
  position: Software Developer
  timeperiod: September 2014 - Juli 2015
  website: https://www.init.de
  description:
    - Entwicklung von Anwendungen, die auf das CMS Drupal basieren für Kunden aus der Verwaltung und Gesundheit.
    - Performanceoptimierungen, On-Site Support, Migration.

- company: init AG für digitale Kommunikation
  position: dualer Student
  website: https://www.init.de
  timeperiod: Oktober 2011 - September 2014
  description:
    - Mitwirkung an Projekten die auf das CMS Drupal basieren
    - Site-Building & Modulentwicklung

education:
- degree: Bachelor of Science Informatik
  timeperiod: Oktober 2011 - September 2014
  description: "Hochschule für Wirtschaft und Recht, Berlin"
  additionaldescription:
    - Duales Studium
    - Vertiefungen in Verteilte Systeme & Maschinelles Lernen
    - 'Studiumsprojekt: "WebRTC: Integration of Internet telephone and Web"'
    - "Bachelorthema: Konzeption, Implementierung und prototypische Anwendung einer dynamischen CMS-unabhängigen Web-Schnittstelle"
  website: https://www.hwr-berlin.de/

- degree: Abitur
  timeperiod: März 2009 - Dezember 2011
  description: Ulrich von Hutten Gymnasium Berlin

# skill level goes 0 to 100
skills:
- name: Drupal 8 & 7
  level: 99
- name: Acquia BLT
  level: 90
- name: Docker
  level: 85
- name: Acquia Cloud
  level: 85
- name: React
  level: 85
- name: MySQL
  level: 85
- name: AngularJS
  level: 70

languages:
- name: Deutsch
  level: Muttersprache
- name: Türkisch
  level: Muttersprache
- name: Englisch
  level: C1

hobbies:
- name: Familie
  iconClass: fa fa-heart

- name: Open Source Software
  iconClass: fa fa-unlock

certificates:
- name: Acquia Certified Developer – D7
  date: April 2016
  url: https://certification.acquia.com/registry?fname=Engin&lname=Yilmaz&city=&state=&country=&org=&exam=All

contact:
  email: ey@esyi.de
  phone: +491731754426
  street: Saalestraße 41
  city: 12055 Berlin
  website: https://engin.berlin
  github: ey-
  xing:
    name: xing.com/profile/Engin_Yilmaz55
    link: https://www.xing.com/profile/Engin_Yilmaz55
  linkedin:
    name: linkedin.com/in/engin-yilmaz-830748b2
    link: https://www.linkedin.com/in/engin-yilmaz-830748b2
  drupal:
    link: https://drupal.org/u/eyilmaz
    name: drupal.org/u/eyilmaz
# en, de, fr, pt, ca, cn, it, es, th, pt-br, ru, sv, id, hu, pl, ja, ka, nl, he, zh-tw, lt, ko, el
lang: de
`
