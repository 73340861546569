// Hebrew
const lang = {
    contact: 'יצירת קשר',
    experience: 'ניסיון',
    education: 'השכלה',
    skills: 'כישורים',
    projects: 'פרויקטים',
    contributions: 'תרומות',
    about: 'תמצית'
};
export default lang;
