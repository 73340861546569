// Indonesian
const lang = {
    contact: 'Kontak',
    experience: 'Pengalaman',
    education: 'Pendidikan',
    skills: 'Keterampilan',
    projects: 'Proyek',
    contributions: 'Kontribusi',
    about: 'Tentang Saya'
};
export default lang;
