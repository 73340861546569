// Japanese
const lang = {
    contact: '連絡先',
    experience: '職務経歴',
    education: '学歴',
    skills: 'スキル',
    projects: 'プロジェクト',
    contributions: '貢献',
    about: '自己紹介'
};
export default lang;
