// Hungarian
const lang = {
    contact: 'Kapcsolat',
    experience: 'Munkatapasztalat',
    education: 'Tanulmány',
    skills: 'Készségek',
    projects: 'Projektek',
    contributions: 'Hozzájárulások',
    about: 'Rólam'
};
export default lang;
