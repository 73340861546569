// Polish
const lang = {
    contact: 'Kontakt',
    experience: 'Doświadczenie',
    education: 'Wykształcenie',
    skills: 'Umiejętności',
    projects: 'Projektowanie',
    contributions: 'Składki',
    about: 'O mnie'
};
export default lang;
