import { render, staticRenderFns } from "./left-right-rtl.vue?vue&type=template&id=5b42eea9&scoped=true&"
import script from "./left-right-rtl.vue?vue&type=script&lang=js&"
export * from "./left-right-rtl.vue?vue&type=script&lang=js&"
import style0 from "./left-right-rtl.vue?vue&type=style&index=0&id=5b42eea9&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b42eea9",
  null
  
)

component.options.__file = "left-right-rtl.vue"
export default component.exports