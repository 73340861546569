// Italian
const lang = {
    contact: 'Contatti',
    experience: 'Esperienza professionale',
    education: 'Formazione',
    skills: 'Competenze',
    contributions: 'Contributi',
    projects: 'Progetti',
    about: 'Su di me'
};
export default lang;
