// Portuguese
const lang = {
    contact: 'Contato',
    experience: 'Experiência Profissional',
    /* You can choose, "Educação" or "Formação Acadêmica"! But the second one is more professional and is more used. */
    education: 'Formação Acadêmica',
    skills: 'Competências',
    projects: 'Projetos',
    contributions: 'Contribuições',
    about: 'Sobre',
    born: 'Nascido em',
    bornIn: 'em'
};
export default lang;
