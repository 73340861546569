import { render, staticRenderFns } from "./side-bar.vue?vue&type=template&id=2cb64e26&scoped=true&"
import script from "./side-bar.vue?vue&type=script&lang=js&"
export * from "./side-bar.vue?vue&type=script&lang=js&"
import style0 from "./side-bar.vue?vue&type=style&index=0&id=2cb64e26&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cb64e26",
  null
  
)

component.options.__file = "side-bar.vue"
export default component.exports