// French
const lang = {
    contact: 'Contact',
    born: 'Né en',
    bornIn: 'à',
    experience: 'Expérience professionelle',
    education: 'Formation',
    skills: 'Compétences',
    projects: 'Projets',
    contributions: 'Contributions',
    about: 'À propos de moi'
};
export default lang;
