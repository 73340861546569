// Portuguese
const lang = {
    contact: 'Contactos',
    experience: 'Experiência Profissional',
    education: 'Educação',
    skills: 'Competências',
    projects: 'Projetos',
    contributions: 'Contribuições',
    about: 'Sobre mim'
};
export default lang;
