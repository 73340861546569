/* eslint unicode-bom: "off" */
// Chinese (Simplified)
const lang = {
    contact: '联系方式',
    experience: '工作经历',
    education: '教育经历',
    skills: '技能专长',
    projects: '项目',
    contributions: '贡献',
    about: '自我介绍'
};
export default lang;
